<template>
  <div class="errPage-container">
    <div class="flex justify-center flex-col items-center center_bg">
      <img :src="srcImg" width="313" height="428" alt="Girl has dropped her ice cream.">
      <h2>{{title}} <span @click="goHome" class="span-primary cursor-pointer">返回工作台</span></h2>
    </div>
  </div>
</template>

<script setup>
import errImage from "@/assets/403/403.png";
import {getCurrentInstance, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

let {proxy} = getCurrentInstance();
const props  =defineProps({
  errCode: {
    type: String,
    default: '403'
  },
  title:{
    type: String,
    default: ''
  },
  srcImg:{
    default: ''
  }
})


const store = useStore();
const router = useRouter()
const goHome = () => {
  sessionStorage.removeItem('appid')
  sessionStorage.removeItem('imgUrl')
  sessionStorage.removeItem('appName')
  sessionStorage.removeItem('appNameEnName')
  sessionStorage.removeItem('routerList')
  sessionStorage.removeItem('menuList')
  sessionStorage.removeItem('editableTabs')
  sessionStorage.removeItem('topic')
  store.commit("SET_APPINFO", {})
  router.push('/app')
}

</script>

<style lang="less" scoped>
.errPage-container {
  width: 100vw;
  height: 100vh;
  padding: 20px;
  position: relative;

  h2 {
    color: #B9B9B9;
  }

  .center_bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pan-back-btn {
    position: absolute;
    top: 20px;
    left: 20px
  }

  .pan-gif {
    margin: 0 auto;
    display: block;
  }

  .pan-img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .text-jumbo {
    font-size: 60px;
    font-weight: 700;
    color: #484848;
  }

  .list-unstyled {
    font-size: 14px;

    li {
      padding-bottom: 5px;
    }

    a {
      color: #008489;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
